import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { FormatSimpleFormContent } from "./components/FormatSimpleFormContent";

export function FormatEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <FormatSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
