import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { ContentTypeSimpleFormContent } from "./components/ContentTypeSimpleFormContent";

export function ContentTypeEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <ContentTypeSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
