import { SimpleForm, Create } from "react-admin";

import { CreateToolbar } from "../../common/CreateToolbar";
import { LicenseSimpleFormContent } from "./components/LicenseSimpleFormContent";

export function LicenseCreate(): JSX.Element {
    return (
        <Create>
            <SimpleForm defaultValues={{ publicationDate: new Date() }} toolbar={<CreateToolbar />}>
                <LicenseSimpleFormContent />
            </SimpleForm>
        </Create>
    );
}
