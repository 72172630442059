import type { AuthProvider, UserIdentity } from "react-admin";
import { dummyArgForOpsWoArg, fetcher } from "../data";
import { HttpStatusCode } from "../utils/HttpStatusCode";
import isNumber from "lodash/isNumber";
import { isDefined, isString } from "../utils/typedLoDashUtils";

// Create typed operations related to auth :
const doLogin = fetcher.path("/api/login").method("post").create();
const doTokenLogin = fetcher.path("/api/login/portal/admin/{token}").method("get").create();
const doLogout = fetcher.path("/api/logout").method("get").create();
const isLogged = fetcher.path("/api/checkLogged").method("get").create();
const getCurrentUser = fetcher.path("/api/user/user/current").method("get").create();

interface MedNumUserIdentity extends UserIdentity {
    roles: readonly string[];
}

export async function tokenLogin(authToken: Readonly<{ token: string }>): Promise<void> {
    let doLoginResult: string | true = "Unknown error";

    try {
        const response = await doTokenLogin(authToken);
        if (response.status === HttpStatusCode.OK) {
            doLoginResult = true;
        }
    } catch (error: unknown) {
        if (error instanceof doLogin.Error) {
            const typedError = error.getActualType();
            if (typedError.status === HttpStatusCode.BAD_REQUEST) {
                doLoginResult = "BAD_REQUEST";
            } else if (typedError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                doLoginResult = "INTERNAL_SERVER_ERROR";
            }
        }
    }

    if (doLoginResult === true) {
        return Promise.resolve();
    }

    return Promise.reject(new Error(`Failed to login: ${doLoginResult}.`));
}

async function login(credentials: Readonly<{ username: string; password: string }>): Promise<void> {
    let doLoginResult: string | true = "Unknown error";

    try {
        const response = await doLogin(credentials);
        if (response.status === HttpStatusCode.OK && response.data.content === true) {
            doLoginResult = true;
        }
    } catch (error: unknown) {
        if (error instanceof doLogin.Error) {
            const typedError = error.getActualType();
            if (typedError.status === HttpStatusCode.BAD_REQUEST) {
                doLoginResult = "BAD_REQUEST";
            } else if (typedError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                doLoginResult = "INTERNAL_SERVER_ERROR";
            }
        }
    }

    if (doLoginResult === true) {
        return Promise.resolve();
    }

    return Promise.reject(new Error(`Failed to login: ${doLoginResult}.`));
}

async function logout(): Promise<void> {
    let doLogoutResult: string | true = "Unknown error";

    try {
        const response = await doLogout(dummyArgForOpsWoArg);
        if (response.status === HttpStatusCode.OK && response.data.content === true) {
            doLogoutResult = true;
        }
    } catch (error: unknown) {
        if (error instanceof doLogout.Error) {
            const typedError = error.getActualType();
            if (typedError.status === HttpStatusCode.BAD_REQUEST) {
                doLogoutResult = "BAD_REQUEST";
            } else if (typedError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                doLogoutResult = "INTERNAL_SERVER_ERROR";
            }
        }
    }

    if (doLogoutResult === true) {
        return Promise.resolve();
    }

    return Promise.reject(new Error(`Failed to logout: ${doLogoutResult}.`));
}

async function checkAuth(): Promise<void> {
    let checkIsLoggedResult: string | true = "Unknown error";

    try {
        const { status, data } = await isLogged(dummyArgForOpsWoArg);
        if (status === HttpStatusCode.OK) {
            checkIsLoggedResult = data.content === true || "Not logged in";
        }
    } catch (error: unknown) {
        if (error instanceof isLogged.Error) {
            const typedError = error.getActualType();
            if (typedError.status === HttpStatusCode.BAD_REQUEST) {
                checkIsLoggedResult = "BAD_REQUEST";
            } else if (typedError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                checkIsLoggedResult = "INTERNAL_SERVER_ERROR";
            }
        }
    }

    if (checkIsLoggedResult === true) {
        return Promise.resolve();
    }

    return Promise.reject(new Error(`Not logged in: ${checkIsLoggedResult}.`));
}

async function checkError(error: Readonly<{ status: HttpStatusCode }>): Promise<void> {
    const { status } = error;
    if (status === HttpStatusCode.UNAUTHORIZED || status === HttpStatusCode.FORBIDDEN) {
        // await logout();
        //return Promise.reject(new Error('Not authenticated'));
    }

    // Other error code (404, 500, etc): no need to log out :
    return Promise.resolve();
}

async function getIdentity(): Promise<MedNumUserIdentity> {
    let getCurrentUserResult: MedNumUserIdentity | string = "Unknown error";

    try {
        const { status, data } = await getCurrentUser(dummyArgForOpsWoArg);
        if (status === HttpStatusCode.OK) {
            const { content } = data;
            if (content) {
                const { id: identifier, firstName, lastName, roles = [], username = "" } = content;
                if (isNumber(identifier)) {
                    getCurrentUserResult = {
                        id: identifier,
                        fullName:
                            isDefined(firstName) && isDefined(lastName) && firstName !== "" && lastName !== ""
                                ? `${firstName} ${lastName}`
                                : username,
                        roles
                    };
                }
            }
        }
    } catch (error: unknown) {
        if (error instanceof getCurrentUser.Error) {
            const typedError = error.getActualType();
            if (typedError.status === HttpStatusCode.BAD_REQUEST) {
                getCurrentUserResult = "BAD_REQUEST";
            } else if (typedError.status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                getCurrentUserResult = "INTERNAL_SERVER_ERROR";
            }
        }
    }

    if (!isString(getCurrentUserResult)) {
        return Promise.resolve(getCurrentUserResult);
    }

    return Promise.reject(new Error(`Failed to login: ${getCurrentUserResult}.`));
}

async function getPermissions(): Promise<string[]> {
    try {
        const { roles } = await getIdentity();
        return [...roles];
    } catch (error: unknown) {
        return Promise.reject(new Error(`Failed to get user permissions ${JSON.stringify(error)}`));
    }
}

export const sessionCookieAuthProvider: AuthProvider = {
    login,
    tokenLogin,
    logout,
    checkAuth,
    checkError,
    getPermissions,
    getIdentity
};
