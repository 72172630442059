// cSpell: ignore connexion
import { AppLocationContext } from "@react-admin/ra-navigation";
import type { CoreLayoutProps } from "react-admin";
import { Admin, CustomRoutes, Layout, Resource, defaultTheme } from "react-admin";
import { QueryClient } from "react-query";
import { HashRouter, Route } from "react-router-dom";

import { ThemeOptions } from "@mui/material";
import { sessionCookieAuthProvider } from "./auth";
import type { MpmRecord } from "./data";
import { dataProvider } from "./data";
import { i18nProvider } from "./i18n";
import { TokenAuth } from "./utils/components/token/TokenAuth";

import { Menu } from "./Menu";
import { MpmResource } from "./resources";

const classes = {
    fullWidth: { width: "100%" }
};

const theme: ThemeOptions = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components
        // RaDatagrid: {
        //     styleOverrides: {
        //         root: {
        //             '& .RaDatagrid-headerCell': {
        //                 fontWeight: 'bold'
        //             }
        //         }
        //     }
        // }
    }
};

function MainLayout(props: CoreLayoutProps): JSX.Element {
    const { dashboard } = props;

    //const { authenticated: isAuthenticated } = useAuthState();
    //const { fetchAdministrativeEntityTree } = useContext(AdministrativeEntityContext);

    // useEffect(() => {
    //     if (isAuthenticated ?? false) {
    //         // eslint-disable-next-line no-void
    //         void fetchAdministrativeEntityTree();
    //     }
    // }, [isAuthenticated, fetchAdministrativeEntityTree]);

    return (
        // eslint-disable-next-line no-implicit-coercion
        <AppLocationContext hasDashboard={!!dashboard}>
            <Layout sx={classes.fullWidth} {...props} menu={Menu} />
        </AppLocationContext>
    );
}

export function App(): JSX.Element | null {
    const queryClient = new QueryClient();

    return (
        <HashRouter>
            <Admin
                queryClient={queryClient}
                i18nProvider={i18nProvider}
                authProvider={sessionCookieAuthProvider}
                dataProvider={dataProvider}
                layout={MainLayout}
                theme={theme}
            >
                {MpmResource.getAll().map(({ name, props }: MpmResource<MpmRecord>): JSX.Element => {
                    const { icon: _icon, ...otherProps } = props;
                    return <Resource key={name} name={name} {...otherProps} />;
                })}
                <CustomRoutes>
                    <Route path="/connexion-par-token" element={<TokenAuth />} />
                </CustomRoutes>
            </Admin>
        </HashRouter>
    );
}
