import { Create } from "react-admin";

import { SerieSimpleFormContent } from "./components/SerieSimpleFormContent";

export function SerieCreate(): JSX.Element {
    return (
        <Create>
            <SerieSimpleFormContent />
        </Create>
    );
}
