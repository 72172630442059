/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import {
    ArrayInput,
    AutocompleteInput,
    Button,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TextInput,
    required,
    useArrayInput,
    useGetOne,
    useInput,
    useTranslate
} from "react-admin";

import { v4 as uuidv4 } from "uuid";

import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { useInputStyles } from "../../../utils/useInputStyles";

import { TitleTextField } from "../../../common/TitleTextField";
import { useCallback, useState } from "react";
import { SortableList } from "../../../utils/components/sortable/SortableList";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

function SortableListSelectInput(props: { optionText: string; targetType: string; setTargetId: (id: any) => void }) {
    return (
        <ReferenceInput source="targetId" reference={props.targetType}>
            <AutocompleteInput onChange={(targetId) => props.setTargetId(targetId)} optionText={props.optionText} optionValue="id" />
        </ReferenceInput>
    );
}

function SortableListItemLabel(props: { targetType: string; targetId: string; labelFieldId: string }) {
    const { data } = useGetOne(props.targetType, { id: props.targetId });

    return <span>{data?.[props.labelFieldId]}</span>;
}

function SortableListAddButton(props: any) {
    const t = useTranslate();
    const [targetType, setTargetType] = useState("selection");
    const [targetId, setTargetId] = useState();

    const onTargetTypeChange = useCallback(
        (event: any) => {
            const newTargetType = event.target.value;

            if (newTargetType != targetType) {
                setTargetType(newTargetType);
                setTargetId(undefined);
            }
        },
        [setTargetType, targetType, setTargetId]
    );

    const onAdd = useCallback(() => {
        props.add({ id: uuidv4(), targetId, targetType });
    }, [targetId, targetType]);

    return (
        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <FormControl
                sx={{
                    marginTop: "4px",
                    width: "12rem",
                    marginRight: "1rem",
                    "& .MuiSelect-select": {
                        paddingTop: "4px"
                    }
                }}
            >
                <Select margin="none" defaultValue={targetType} onChange={onTargetTypeChange}>
                    <MenuItem value="product">{t("resources.homepage.choices.targetType.product")}</MenuItem>
                    <MenuItem value="serie">{t("resources.homepage.choices.targetType.serie")}</MenuItem>
                    <MenuItem value="character">{t("resources.homepage.choices.targetType.character")}</MenuItem>
                    <MenuItem value="selection">{t("resources.homepage.choices.targetType.selection")}</MenuItem>
                </Select>
            </FormControl>
            <SortableListSelectInput
                optionText={["selection", "product"].includes(targetType) ? "title" : "name"}
                targetType={targetType}
                setTargetId={setTargetId}
            />
            <Button
                style={{ marginTop: "4px" }}
                variant="contained"
                size="small"
                label={t("resources.homepage.buttons.addLayerItem")}
                onClick={onAdd}
            />
        </div>
    );
}

function SortableListFormIterator(props: any) {
    const t = useTranslate();
    const { append, replace, fields } = useArrayInput(props);
    const [items, setItems] = useState<any[]>(fields);

    const addItem = useCallback(
        (item: any) => {
            append(item);
            items.push(item);
            setItems(items);
        },
        [append, items]
    );

    const removeItem = useCallback(
        (id: string) => {
            const newItems = items.filter((item) => item.id != id);
            replace(newItems);
            setItems(newItems);
        },
        [replace, items]
    );

    return (
        <div style={{ paddingTop: "1rem" }}>
            <SortableListAddButton add={addItem} />
            <div
                style={{
                    width: "100%",
                    padding: "0.5rem 0.2rem",
                    overflowX: "auto"
                }}
            >
                <SortableList
                    items={items}
                    onChange={(updatedItems) => {
                        setItems(updatedItems);
                        replace(updatedItems);
                    }}
                    renderItem={(item) => (
                        <SortableList.Item id={item.id}>
                            <div
                                style={{
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    paddingLeft: "2rem",
                                    paddingRight: "2rem",
                                    minWidth: "20rem"
                                }}
                            >
                                <Chip
                                    color="default"
                                    size="small"
                                    label={t(`resources.homepage.choices.targetType.${item.targetType}`)}
                                    style={{ marginRight: "1rem" }}
                                />
                                <SortableListItemLabel
                                    targetType={item.targetType}
                                    targetId={item.targetId}
                                    labelFieldId={["selection", "product"].includes(item.targetType) ? "title" : "name"}
                                />

                                <div style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)" }}>
                                    <SortableList.DragHandle />
                                </div>
                                <div style={{ position: "absolute", right: "0px", top: "50%", transform: "translateY(-50%)" }}>
                                    <Button
                                        onClick={() => {
                                            removeItem(item.id);
                                        }}
                                        startIcon={<HighlightOffRoundedIcon />}
                                        sx={{
                                            minWidth: "20px",
                                            "& .MuiButton-startIcon": {
                                                margin: "0"
                                            },
                                            "& .MuiSvgIcon-root": {
                                                fontSize: "24px"
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </SortableList.Item>
                    )}
                />
            </div>
        </div>
    );
}

function LayerItemSelectForm(props: any) {
    const classes = useInputStyles;
    const t = useTranslate();
    const selectInput = useInput({ source: props.getSource("targetType") });
    const [targetType, setTargetType] = useState(selectInput.field.value ?? "product");

    const onTargetTypeChange = useCallback(
        (event: any) => {
            const newTargetType = event.target.value;

            if (newTargetType != targetType) {
                setTargetType(newTargetType);
            }
        },
        [setTargetType, targetType]
    );

    return (
        <div style={{ paddingTop: "1rem" }}>
            <SelectInput
                validate={required()}
                label={t("resources.homepage.fields.targetType")}
                source={props.getSource("targetType")}
                onChange={onTargetTypeChange}
                choices={[
                    { id: "product", name: t("resources.homepage.choices.targetType.product") },
                    { id: "serie", name: t("resources.homepage.choices.targetType.serie") },
                    { id: "character", name: t("resources.homepage.choices.targetType.character") },
                    { id: "selection", name: t("resources.homepage.choices.targetType.selection") }
                ]}
            />
            <ReferenceInput source={props.getSource("targetId")} reference={targetType}>
                <AutocompleteInput
                    sx={classes.largeField}
                    label={t(`resources.${targetType}.name`, { smart_count: 1 })}
                    optionValue="id"
                    optionText={["selection", "product"].includes(targetType) ? "title" : "name"}
                />
            </ReferenceInput>
        </div>
    );
}

function LayerFields(props: { type: string; getSource: any }) {
    const classes = useInputStyles;

    const t = useTranslate();

    if (!props.type) {
        return <></>;
    }

    if (props.type == "text") {
        return (
            <>
                <TextInput source={props.getSource("content")} fullWidth multiline />
            </>
        );
    } else if (props.type == "item-list") {
        return (
            <>
                <SelectInput
                    label={t("resources.homepage.fields.itemDisplayType")}
                    source={props.getSource("itemDisplayType")}
                    style={{ width: "20rem" }}
                    choices={[
                        { id: "thumbnail", name: t("resources.homepage.choices.itemDisplayType.thumbnail") },
                        { id: "banner", name: t("resources.homepage.choices.itemDisplayType.banner") }
                    ]}
                />
                <ArrayInput source={props.getSource("items")}>
                    <SortableListFormIterator />
                </ArrayInput>
            </>
        );
    } else if (props.type == "highlight") {
        return <LayerItemSelectForm {...props} />;
    }

    return (
        <>
            <SelectInput
                label={t("resources.homepage.fields.itemDisplayType")}
                source={props.getSource("itemDisplayType")}
                style={{ width: "20rem" }}
                choices={[
                    { id: "thumbnail", name: t("resources.homepage.choices.itemDisplayType.thumbnail") },
                    { id: "banner", name: t("resources.homepage.choices.itemDisplayType.banner") }
                ]}
            />
            <TextInput source={props.getSource("targetType")} defaultValue="selection" style={{ display: "none" }} />
            <ReferenceInput source={props.getSource("targetId")} reference="selection">
                <AutocompleteInput
                    sx={classes.largeField}
                    label={t("resources.selection.name", { smart_count: 1 })}
                    optionValue="id"
                    optionText="title"
                />
            </ReferenceInput>
        </>
    );
}

function ArrayItem(props: any) {
    const t = useTranslate();

    const { field } = useInput({ source: props.getSource("type") });

    const fieldType = field.value ? field.value : "selection";

    let i18nLayerType = fieldType + "LayerType";

    if (fieldType == "item-list") {
        i18nLayerType = "itemListLayerType";
    }

    return (
        <div style={{ width: "100%" }}>
            <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
                <Divider>
                    <Chip color="primary" variant="outlined" label={t(`resources.homepage.fields.${i18nLayerType}`)} />
                </Divider>
            </div>
            <TextInput source={props.getSource("type")} value={fieldType} style={{ display: "none" }} />
            <TextInput source={props.getSource("title")} label={t("resources.homepage.fields.title")} />
            <TextInput source={props.getSource("description")} label={t("resources.homepage.fields.description")} fullWidth multiline />
            <LayerFields getSource={props.getSource} type={fieldType} />
        </div>
    );
}

function AddLayerButton(props: { add: (layerType: string) => void }) {
    const t = useTranslate();
    const [layerType, setLayerType] = useState("selection");
    const onLayerTypeChange = useCallback(
        (event: any) => {
            const newLayerType = event.target.value;

            if (newLayerType != layerType) {
                setLayerType(newLayerType);
            }
        },
        [setLayerType, layerType]
    );

    return (
        <div style={{ paddingTop: "1rem" }}>
            <FormControl
                sx={{
                    marginTop: "4px",
                    width: "12rem",
                    marginRight: "1rem",
                    "& .MuiSelect-select": {
                        paddingTop: "4px"
                    }
                }}
            >
                <Select margin="none" defaultValue="selection" onChange={onLayerTypeChange}>
                    <MenuItem value="selection">{t("resources.homepage.choices.layerType.selection")}</MenuItem>
                    <MenuItem value="text">{t("resources.homepage.choices.layerType.text")}</MenuItem>
                    <MenuItem value="item-list">{t("resources.homepage.choices.layerType.itemList")}</MenuItem>
                    <MenuItem value="highlight">{t("resources.homepage.choices.layerType.highlight")}</MenuItem>
                </Select>
            </FormControl>
            <Button
                variant="contained"
                size="medium"
                label={t("resources.homepage.buttons.addLayer")}
                onClick={() => props.add(layerType)}
            />
        </div>
    );
}

function LayerIterator(props: any) {
    const { append } = useArrayInput(props);

    const addLayer = useCallback(
        (layerType: string) => {
            append({ type: layerType });
        },
        [append]
    );

    return (
        <div style={{ paddingTop: "1rem" }}>
            <SimpleFormIterator
                {...props}
                disableAdd
                disableClear
                sx={{
                    "& RaSimpleFormIterator-line": {
                        border: "none"
                    },
                    "& .RaSimpleFormIterator-form": {
                        width: "50%"
                    }
                }}
            >
                <FormDataConsumer>{(dataProps) => <ArrayItem {...dataProps} />}</FormDataConsumer>
            </SimpleFormIterator>
            <Divider />
            <AddLayerButton add={addLayer} />
        </div>
    );
}

export function HomeSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");

    return (
        <>
            <TitleTextField type={isCreateMode ? "create" : "edit"} />
            <TextInput sx={classes.inputField} validate={required()} source="title" />
            <TextInput sx={classes.inputField} source="description" fullWidth multiline />
            <ArrayInput source="layers">
                <LayerIterator />
            </ArrayInput>
        </>
    );
}
