import { StrictMode as ReactStrictMode } from "react";
import { render } from "react-dom";
import { App } from "./App";
import { AdministrativeEntityContextProvider } from "./utils/context/administrative-entity-context";

render(
    <ReactStrictMode>
        <AdministrativeEntityContextProvider>
            <App />
        </AdministrativeEntityContextProvider>
    </ReactStrictMode>,
    document.getElementById("root")
);
