// cSpell: ignore camelcase
/* eslint-disable new-cap */
import { MenuItemNode, MultiLevelMenu } from "@react-admin/ra-navigation";
import { usePermissions, useTranslate } from "react-admin";
import type { DeepReadonly } from "ts-essentials";
import SvgIcon from "@mui/material/SvgIcon";

import type { MpmRecord } from "./data";
import { MpmResource } from "./resources";
import { assertIsDefined } from "./utils/assertions";

export function Menu(): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { permissions } = usePermissions();
    const translate = useTranslate();
    return (
        <MultiLevelMenu initialOpen>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
            {MpmResource.getAllParentNames(permissions).map((parentMenu) => {
                const { name: parentName, to: redirect, label, icon: parentIcon } = parentMenu;
                assertIsDefined(parentIcon);
                return (
                    <MenuItemNode key={parentName} name={parentName} to={redirect} label={label} icon={<SvgIcon component={parentIcon} />}>
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
                        {MpmResource.getPermittedByParentName(parentName, permissions).map(
                            ({ name: childName, props: { icon } }: DeepReadonly<MpmResource<MpmRecord>>): JSX.Element | null => {
                                if (!icon) {
                                    return null;
                                }

                                return (
                                    <MenuItemNode
                                        key={childName}
                                        name={childName}
                                        // eslint-disable-next-line camelcase
                                        label={translate(`resources.${childName}.name`, { smart_count: 2 })}
                                        to={`/${childName}`}
                                        icon={<SvgIcon component={icon} />}
                                    />
                                );
                            }
                        )}
                    </MenuItemNode>
                );
            })}
        </MultiLevelMenu>
    );
}
