import { Create } from "react-admin";

import { SeasonSimpleFormContent } from "./components/SeasonSimpleFormContent";

export function SeasonCreate(): JSX.Element {
    return (
        <Create>
            <SeasonSimpleFormContent />
        </Create>
    );
}
