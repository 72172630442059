/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { SimpleForm, TextInput, required } from "react-admin";

import { MarkdownInput } from "@react-admin/ra-markdown";

import { useInputStyles } from "../../../utils/useInputStyles";

import { TitleTextField } from "../../../common/TitleTextField";
import { CreateToolbar } from "../../../common/CreateToolbar";
import { EditToolbar } from "../../../common/EditToolbar";

export function StaticPageSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");
    const formProps: any = {};

    if (isCreateMode) {
        formProps["toolbar"] = <CreateToolbar />;
    } else {
        // Edit
        formProps["toolbar"] = <EditToolbar />;
    }

    return (
        <SimpleForm {...formProps}>
            <TitleTextField type={isCreateMode ? "create" : "edit"} />
            <TextInput sx={classes.inputField} validate={required()} source="code" />
            <TextInput sx={classes.inputField} validate={required()} source="title" />
            <TextInput sx={classes.inputField} source="description" fullWidth multiline />
            <MarkdownInput source="content" />
        </SimpleForm>
    );
}
