import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { AdminUserSimpleFormContent } from "./components/AdminUserSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function AdminUserEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="name" />}>
            <SimpleForm toolbar={<EditToolbar />}>
                <AdminUserSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
