import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { ArtistSimpleFormContent } from "./components/ArtistSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function ArtistEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="fullName" />}>
            <SimpleForm toolbar={<EditToolbar />}>
                <ArtistSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
