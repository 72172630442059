import { Show, SimpleShowLayout, TextField } from "react-admin";
import { ShowHeader } from "../../utils/header";

export function LicenseShow(): JSX.Element {
    return (
        <Show title={<ShowHeader field="name" />}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="title" />
                <TextField source="orderId" />
            </SimpleShowLayout>
        </Show>
    );
}
