import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { isDefined } from "../../utils/typedLoDashUtils";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import type { FormatDTO } from "./FormatDTO";
import type { FormatRecord } from "./FormatRecord";
import { convertIdFilter } from "../../utils/filterUtils";

const findAllTranslated = fetcher.path("/api/content/format/findAll").method("post").create();
const getTranslated = fetcher.path("/api/content/format/{uuid}").method("get").create();
const updateOne = fetcher.path("/api/content/format/{uuid}").method("put").create();
const createOne = fetcher.path("/api/content/format/").method("post").create();
const deleteOne = fetcher.path("/api/content/format/{uuid}").method("delete").create();
const getAllByUuids = fetcher.path("/api/content/format/allByUuids").method("get").create();

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns FormatRecord
 */

function mapDTOToRecord(dto: FormatDTO): FormatRecord {
    // DTO from backend
    const { id = 0, uuid = "", name = "", description = "" } = dto;

    // Record for front-end
    return {
        shortId: id,
        id: uuid,
        name,
        description
    };
}

/**
 * Parses the record from frontend to DTO for backend
 * @param record
 * @returns FormatDTO
 */

function mapRecordToDTO(record: FormatRecord): FormatDTO {
    // Record from front-end
    const { shortId = 0, id = "", name = "", description = "" } = record;

    // DTO for back-end
    return {
        id: shortId,
        name,
        uuid: id as string,
        description
    };
}

interface FormatFilters extends BaseFilters {
    q?: string;
    id: string;
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<FormatRecord>> {
    const { q: searchTerms, ...filters } = params.filter as FormatFilters;

    let parameters: FetchArgType<typeof findAllTranslated> = {
        filter: convertIdFilter(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    if (isDefined(searchTerms)) {
        parameters = {
            ...parameters,
            filter: { ...parameters.filter, searchTerms }
        };
    }

    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };
}

async function getOne(params: LangParams | (number | string)): Promise<FormatRecord> {
    const {
        data: { content: formatDTO }
    } = await getTranslated({
        uuid: params as string
    });
    assertIsDefined(formatDTO);

    return mapDTOToRecord({ id: formatDTO.id ?? 0, ...formatDTO });
}

async function getMany(uuids: readonly (number | string)[]): Promise<GetManyResult<FormatRecord>> {
    const {
        data: { content: formatDTO }
    } = await getAllByUuids({
        uuids: uuids as string[]
    });
    assertIsDefined(formatDTO);

    return { data: await Promise.all(formatDTO.map((dto) => mapDTOToRecord({ id: dto.id ?? 0, ...dto }))) };
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<FormatRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(params: DeepReadonly<UpdateParams<FormatRecord>>): Promise<UpdateResult<FormatRecord>> {
    const payload = mapRecordToDTO(params.data as FormatRecord);

    const parameters = { uuid: payload.uuid ?? "", ...payload };

    const { data } = await updateOne(parameters);
    const userDTO = data.content;
    assertIsDefined(userDTO);

    return { data: mapDTOToRecord({ id: parameters.id, ...userDTO }) };
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(params: DeepReadonly<CreateParams>): Promise<CreateResult<FormatRecord>> {
    const payload = mapRecordToDTO(params.data as any);

    assertIsDefined(payload.name);

    const parameters = { name: payload.name, description: payload.description ?? "" };
    const { data } = await createOne(parameters);
    const formatDTO = data.content;

    assertIsDefined(formatDTO);

    return { data: mapDTOToRecord({ id: formatDTO.id as number, ...formatDTO }) };
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(uuid: number | string): Promise<FormatRecord> {
    const {
        data: { content: isDeleted }
    } = await deleteOne({
        uuid: uuid as string
    });
    assertIsDefined(isDeleted);

    return {} as FormatRecord;
}

async function deleteMany(params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    await Promise.all(params.ids.map((id: string) => _delete(id)));
    return {
        data: params.ids.map((id: string) => {
            id;
        })
    };
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<FormatRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const FormatDataProvider: MpmDataProvider<FormatRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne,
    update,
    updateMany
};
