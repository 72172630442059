import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { HomeSimpleFormContent } from "./components/HomeSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function HomeEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="title" />}>
            <SimpleForm toolbar={<EditToolbar />}>
                <HomeSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
