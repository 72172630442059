import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { isDefined } from "../../utils/typedLoDashUtils";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import type { LicenseDTO } from "./LicenseDTO";
import type { LicenseRecord } from "./LicenseRecord";
import { convertIdFilter } from "../../utils/filterUtils";

const findAll = fetcher.path("/api/legal/license/findAll").method("post").create();
const getOne = fetcher.path("/api/legal/license/{uuid}").method("get").create();
const updateOne = fetcher.path("/api/legal/license/{uuid}").method("put").create();
const createOne = fetcher.path("/api/legal/license/").method("post").create();
const deleteOne = fetcher.path("/api/legal/license/{uuid}").method("delete").create();
const getAllByUuids = fetcher.path("/api/legal/license/allByUuids").method("get").create();

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns LicenseRecord
 */

function mapDTOToRecord(dto: LicenseDTO): LicenseRecord {
    // DTO from backend
    const { id = 0, uuid = "", name = "", title = "", description = "", orderId = 0 } = dto;

    // Record for front-end
    return {
        shortId: id,
        id: uuid,
        name,
        title,
        description,
        orderId
    };
}

/**
 * Parses the record from frontend to DTO for backend
 * @param record
 * @returns LicenseDTO
 */

function mapRecordToDTO(record: LicenseRecord): LicenseDTO {
    // Record from front-end
    const { shortId = 0, id = "", name = "", title = "", description = "", orderId = 0 } = record;

    // DTO for back-end
    return {
        id: shortId,
        name,
        title,
        uuid: id as string,
        description,
        orderId
    };
}

interface LicenseFilters extends BaseFilters {
    q?: string;
    id: string;
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<LicenseRecord>> {
    const { q: searchTerms, ...filters } = params.filter as LicenseFilters;

    let parameters: FetchArgType<typeof findAll> = {
        filter: convertIdFilter(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    if (isDefined(searchTerms)) {
        parameters = {
            ...parameters,
            filter: { ...parameters.filter, searchTerms }
        };
    }

    const { data } = await findAll(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };
}

async function _getOne(params: LangParams | (number | string)): Promise<LicenseRecord> {
    const {
        data: { content: licenseDTO }
    } = await getOne({
        uuid: params as string
    });
    assertIsDefined(licenseDTO);

    return mapDTOToRecord({ id: licenseDTO.id ?? 0, ...licenseDTO });
}

async function getMany(uuids: readonly (number | string)[]): Promise<GetManyResult<LicenseRecord>> {
    const {
        data: { content: licenseDTO }
    } = await getAllByUuids({
        uuids: uuids as string[]
    });
    assertIsDefined(licenseDTO);

    return { data: await Promise.all(licenseDTO.map((dto) => mapDTOToRecord({ id: dto.id ?? 0, ...dto }))) };
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<LicenseRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(params: DeepReadonly<UpdateParams<LicenseRecord>>): Promise<UpdateResult<LicenseRecord>> {
    const payload = mapRecordToDTO(params.data as LicenseRecord);

    const parameters = { uuid: payload.uuid ?? "", ...payload };

    const { data } = await updateOne(parameters);
    const licenseDTO = data.content;
    assertIsDefined(licenseDTO);

    return { data: mapDTOToRecord({ id: parameters.id, ...licenseDTO }) };
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(params: DeepReadonly<CreateParams>): Promise<CreateResult<LicenseRecord>> {
    const payload = mapRecordToDTO(params.data as any);

    assertIsDefined(payload.name);
    const parameters = { ...payload };
    delete (parameters as any)["id"];
    delete (parameters as any)["uuid"];
    const { data } = await createOne(parameters);
    const licenseDTO = data.content;

    assertIsDefined(licenseDTO);

    return { data: mapDTOToRecord({ id: licenseDTO.id as number, ...licenseDTO }) };
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(uuid: number | string): Promise<LicenseRecord> {
    const {
        data: { content: isDeleted }
    } = await deleteOne({
        uuid: uuid as string
    });
    assertIsDefined(isDeleted);

    return {} as LicenseRecord;
}

async function deleteMany(params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    await Promise.all(params.ids.map((id: string) => _delete(id)));
    return {
        data: params.ids.map((id: string) => {
            id;
        })
    };
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<LicenseRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const LicenseDataProvider: MpmDataProvider<LicenseRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne: _getOne,
    update,
    updateMany
};
