/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { BooleanInput, DateInput, TextInput, TabbedForm } from "react-admin";

import { useInputStyles } from "../../../utils/useInputStyles";

import { TitleTextField } from "../../../common/TitleTextField";
import { ImageListInput } from "../../image/components/Form";
import { CreateToolbar } from "../../../common/CreateToolbar";
import { EditToolbar } from "../../../common/EditToolbar";

export function SerieSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");
    const formProps: any = {};

    if (isCreateMode) {
        formProps["toolbar"] = <CreateToolbar />;
    } else {
        // Edit
        formProps["toolbar"] = <EditToolbar />;
    }

    return (
        <TabbedForm {...formProps}>
            <TabbedForm.Tab label="general">
                <TitleTextField type={isCreateMode ? "create" : "edit"} />
                <TextInput sx={classes.inputField} source="name" />
                <TextInput sx={classes.inputField} source="description" fullWidth multiline />
                <ImageListInput targetType="serie" />
            </TabbedForm.Tab>
            <TabbedForm.Tab label="publication">
                <BooleanInput sx={classes.inputField} source="published" />
                <DateInput source="publicationStartedAt" />
                <DateInput source="publicationEndedAt" />
            </TabbedForm.Tab>
        </TabbedForm>
    );
}
