import { useCallback } from "react";
import { Toolbar, SaveButton, useNotify, useRedirect, useResourceContext } from "react-admin";

const classes = {
    root: { display: "flex", gap: "16px" }
};

export function CreateToolbar(): JSX.Element {
    const resource = useResourceContext();
    const notify = useNotify();
    const redirect = useRedirect();

    const redirectToList = useCallback(() => {
        notify("ra.notification.created", { type: "info" });
        redirect("list", resource);
    }, [notify, redirect, resource]);

    const redirectToCreate = useCallback(() => {
        notify("ra.notification.created", { type: "info" });
        redirect("create", resource);

        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }, [notify, redirect, resource]);

    return (
        <Toolbar sx={classes.root}>
            <SaveButton type="button" label="ra.action.create" />
            <SaveButton type="button" label="action.createAndGoToList" variant="text" mutationOptions={{ onSuccess: redirectToList }} />
            <SaveButton type="button" label="action.createAndAdd" variant="text" mutationOptions={{ onSuccess: redirectToCreate }} />
        </Toolbar>
    );
}
