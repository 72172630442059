import { Datagrid, List, ShowButton, TextField, DateField, TextInput, useTranslate, EditButton } from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { YesNoBooleanField } from "../../fields/YesNoBooleanField";
import { ListPagination } from "../../utils/components/ListPagination";

import { ListActions } from "../../common/ListActions";

export function AdminUserList(): JSX.Element {
    const t = useTranslate();

    const filters = [<TextInput key="searchTerms-filter" label={t("resources.adminUser.filters.text")} source="searchTerms" alwaysOn />];

    return (
        <>
            <List pagination={<ListPagination />} perPage={20} filters={filters} actions={<ListActions />}>
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="username" />
                    <TextField source="email" />
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <DateField source="lastLogin" />
                    <YesNoBooleanField source="enabled" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
        </>
    );
}
