/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { Datagrid, ImageField, Labeled, ReferenceManyField, WithRecord, useTranslate } from "react-admin";

export function ImageListField(props: { targetType: string }) {
    const t = useTranslate();

    return (
        <Labeled label={t("resources.image.name", { smart_count: 2 })}>
            <ReferenceManyField reference="image" filter={{ targetType: props.targetType }} target="targetId">
                <Datagrid bulkActionButtons={false}>
                    <WithRecord
                        label={t("resources.image.fields.type")}
                        render={(record) => <span>{t(`resources.image.choices.type.${record.type}`)}</span>}
                    />
                    <ImageField source="url" />
                </Datagrid>
            </ReferenceManyField>
        </Labeled>
    );
}
