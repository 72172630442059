import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { ContentOriginSimpleFormContent } from "./components/ContentOriginSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function ContentOriginEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="name" />}>
            <SimpleForm toolbar={<EditToolbar />}>
                <ContentOriginSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
