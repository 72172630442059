import { useCallback } from "react";
import { useResourceContext, Toolbar, SaveButton, DeleteButton, useNotify, useRedirect, useRefresh } from "react-admin";

const classes = {
    root: { display: "flex", gap: "16px" },
    deleteButton: { marginLeft: "auto" }
};

interface EditToolbarProps {
    // eslint-disable-next-line react/require-default-props
    redirectPath?: string;
    // eslint-disable-next-line react/require-default-props
    canDelete?: boolean;
}

export function EditToolbar({ redirectPath, canDelete = true }: EditToolbarProps): JSX.Element {
    const resource = useResourceContext();
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    const refreshPage = useCallback(() => {
        notify("notification.updated", { type: "info" });
        refresh();
    }, [notify, refresh]);

    const redirectToList = useCallback(() => {
        notify("notification.updated", { type: "info" });
        redirect(redirectPath ?? `/${resource}`);
    }, [notify, redirect, redirectPath, resource]);

    const onDeleteSuccess = useCallback(() => {
        notify("notification.deleted", { type: "info" });
        redirect(redirectPath ?? `/${resource}`);
    }, [notify, redirect, redirectPath, resource]);

    return (
        <Toolbar sx={classes.root}>
            <SaveButton type="button" label="action.update" mutationOptions={{ onSuccess: refreshPage }} />
            <SaveButton type="button" variant="text" label="action.updateAndClose" mutationOptions={{ onSuccess: redirectToList }} />
            {canDelete && <DeleteButton type="button" sx={classes.deleteButton} mutationOptions={{ onSuccess: onDeleteSuccess }} />}
        </Toolbar>
    );
}
