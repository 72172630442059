/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { TextInput, required } from "react-admin";

import { useInputStyles } from "../../../utils/useInputStyles";

import { TitleTextField } from "../../../common/TitleTextField";

export function ArtistSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");

    return (
        <>
            <TitleTextField type={isCreateMode ? "create" : "edit"} />
            <TextInput sx={classes.inputField} validate={required()} source="fullName" />
        </>
    );
}
