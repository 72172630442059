import { Show, SimpleShowLayout, TextField, useTranslate } from "react-admin";
import { ImageListField } from "../image/components/Field";

export function CharacterShow(): JSX.Element {
    const t = useTranslate();

    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <ImageListField targetType="character" />
            </SimpleShowLayout>
        </Show>
    );
}
