import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { LicenseSimpleFormContent } from "./components/LicenseSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function LicenseEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="name" />}>
            <SimpleForm toolbar={<EditToolbar />}>
                <LicenseSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
