import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { ThemeSimpleFormContent } from "./components/ThemeSimpleFormContent";

export function ThemeEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <ThemeSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
