/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import { BooleanInput, PasswordInput, TextInput, required } from "react-admin";

import { i18nProvider } from "../../../i18n";

import { useInputStyles } from "../../../utils/useInputStyles";

import { TitleTextField } from "../../../common/TitleTextField";

const passwordValidation = (value: string, allValues: any) => {
    if (value && value != allValues["confirmedPassword"]) {
        return i18nProvider.translate("resources.adminUser.validation.passwordConfirmationError");
    }

    return undefined;
};

export function AdminUserSimpleFormContent(): JSX.Element {
    const classes = useInputStyles;
    const isCreateMode: boolean = window.location.href.includes("/create");

    return (
        <>
            <TitleTextField type={isCreateMode ? "create" : "edit"} />
            <TextInput sx={classes.inputField} validate={required()} source="username" />
            <TextInput sx={classes.inputField} validate={required()} source="email" />
            <TextInput sx={classes.inputField} source="firstName" />
            <TextInput sx={classes.inputField} source="lastName" />
            <PasswordInput
                sx={classes.inputField}
                source="newPassword"
                inputProps={{ autocomplete: "new-password" }}
                validate={[required(), passwordValidation]}
            />
            <PasswordInput sx={classes.inputField} source="confirmedPassword" />
            <BooleanInput sx={classes.inputField} source="enabled" />
        </>
    );
}
