import { downloadCSV, TopToolbar, CreateButton, ExportButton, FilterButton } from "react-admin";
import type { Exporter } from "react-admin";
import { i18nProvider } from "../i18n";
import jsonExport from "jsonexport/dist";

const EXPORT_FORBIDDEN_KEYS = ["shortId"];

const defaultExporter: Exporter = (data, _, __, resource: any) => {
    // Remove useless keys
    for (const line of data) {
        for (const key of Object.keys(line)) {
            if (!EXPORT_FORBIDDEN_KEYS.includes(key)) {
                continue;
            }
            delete line[key];
        }
    }

    // Get headers
    const firstLine = data?.[0];
    const headers = Object.keys(firstLine);

    // Translate headers
    const rename = [];

    for (const headerId of headers) {
        const defaultTranslation = i18nProvider.translate(`resources.${resource}.fields.${headerId}`, { _: headerId });
        rename.push(i18nProvider.translate(`resources.${resource}.exportFields.${headerId}`, { _: defaultTranslation }));
    }

    return jsonExport(data, { rowDelimiter: ";", headers, rename }, (_err: any, csv: any) => downloadCSV(csv, resource));
};

export function ListActions(): JSX.Element {
    return (
        <TopToolbar sx={{ alignItems: "center" }}>
            <CreateButton />
            <ExportButton exporter={defaultExporter} />
            <FilterButton disableSaveQuery />
        </TopToolbar>
    );
}
