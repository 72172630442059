import { Edit } from "react-admin";

import { SelectionSimpleFormContent } from "./components/SelectionSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function SelectionEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="title" />}>
            <SelectionSimpleFormContent />
        </Edit>
    );
}
