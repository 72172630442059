export function filterToQuery(searchText: string): Record<string, string> {
    return { searchTerms: searchText };
}

export function convertIdFilter(filters: any): any {
    const newFilters = Object.assign({}, filters) as any;

    // Replace id by uuid
    if (filters?.["id"] != null) {
        newFilters["uuid"] = filters["id"];
        delete newFilters["id"];
    }

    return newFilters;
}
