import { Show, SimpleShowLayout, TextField } from "react-admin";

export function ContentTypeShow(): JSX.Element {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
            </SimpleShowLayout>
        </Show>
    );
}
