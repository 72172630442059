import { Create } from "react-admin";

import { SelectionSimpleFormContent } from "./components/SelectionSimpleFormContent";

export function SelectionCreate(): JSX.Element {
    return (
        <Create>
            <SelectionSimpleFormContent />
        </Create>
    );
}
