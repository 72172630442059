import { Edit } from "react-admin";

import { SeasonSimpleFormContent } from "./components/SeasonSimpleFormContent";

export function SeasonEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SeasonSimpleFormContent />
        </Edit>
    );
}
