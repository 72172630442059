import { ArrayField, ChipField, ImageField, Show, SimpleShowLayout, SingleFieldList, TextField, useTranslate } from "react-admin";
import { YesNoBooleanField } from "../../fields/YesNoBooleanField";
import { ImageListField } from "../image/components/Field";

export function ProductShow(): JSX.Element {
    const t = useTranslate();

    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="description" />
                <YesNoBooleanField source="singleContent" />
                <ArrayField source="characters">
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" size="small" />
                    </SingleFieldList>
                </ArrayField>
                <ImageListField targetType="product" />
            </SimpleShowLayout>
        </Show>
    );
}
