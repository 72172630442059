import { Edit, SimpleForm } from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { CharacterSimpleFormContent } from "./components/CharacterSimpleFormContent";

export function CharacterEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <CharacterSimpleFormContent />
            </SimpleForm>
        </Edit>
    );
}
