import { Show, SimpleShowLayout, TextField } from "react-admin";
import { ImageListField } from "../image/components/Field";

export function SerieShow(): JSX.Element {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                <ImageListField targetType="serie" />
            </SimpleShowLayout>
        </Show>
    );
}
