import { Edit } from "react-admin";

import { SerieSimpleFormContent } from "./components/SerieSimpleFormContent";

export function SerieEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <SerieSimpleFormContent />
        </Edit>
    );
}
