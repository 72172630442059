import { SimpleForm, Create } from "react-admin";

import { CreateToolbar } from "../../common/CreateToolbar";
import { FormatSimpleFormContent } from "./components/FormatSimpleFormContent";

export function FormatCreate(): JSX.Element {
    return (
        <Create>
            <SimpleForm defaultValues={{ publicationDate: new Date() }} toolbar={<CreateToolbar />}>
                <FormatSimpleFormContent />
            </SimpleForm>
        </Create>
    );
}
