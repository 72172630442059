import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import { YesNoBooleanField } from "../../fields/YesNoBooleanField";
import { ShowHeader } from "../../utils/header";

export function AdminUserShow(): JSX.Element {
    return (
        <Show title={<ShowHeader field="name" />}>
            <SimpleShowLayout>
                <TextField source="username" />
                <TextField source="email" />
                <TextField source="firstName" />
                <TextField source="lastName" />
                <DateField source="lastLogin" />
                <YesNoBooleanField source="enabled" />
            </SimpleShowLayout>
        </Show>
    );
}
