/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-bind */
import {
    ImageField,
    ImageInput,
    Labeled,
    ReferenceManyField,
    SelectInput,
    SimpleForm,
    WithRecord,
    required,
    useTranslate
} from "react-admin";

import { CreateInDialogButton } from "@react-admin/ra-form-layout";
import { EditableDatagrid, RowForm } from "@react-admin/ra-editable-datagrid";

function ImageTypeSelectInput(props: { source: string }) {
    const t = useTranslate();
    return (
        <SelectInput
            validate={required()}
            source={props.source}
            choices={[
                { id: "thumbnail", name: t("resources.image.choices.type.thumbnail") },
                { id: "banner", name: t("resources.image.choices.type.banner") }
            ]}
        />
    );
}

function ImageAddButton(props: { targetType: string }) {
    const t = useTranslate();

    return (
        <>
            <WithRecord
                render={(record) => (
                    <CreateInDialogButton
                        title={t("resources.image.upload")}
                        label={t("resources.image.upload")}
                        resource="image"
                        record={{
                            targetType: props.targetType,
                            targetId: record.id
                        }}
                    >
                        <SimpleForm>
                            <ImageTypeSelectInput source="type" />
                            <ImageInput source="image" accept="image/*" multiple={false}>
                                <ImageField source="src" />
                            </ImageInput>
                        </SimpleForm>
                    </CreateInDialogButton>
                )}
            />
        </>
    );
}

function ImageForm() {
    return (
        <RowForm>
            <ImageTypeSelectInput source="type" />
            <ImageField source="url" />
        </RowForm>
    );
}

export function ImageListInput(props: { targetType: string }) {
    const t = useTranslate();

    return (
        <Labeled label={t("resources.image.name", { smart_count: 2 })}>
            <>
                <ReferenceManyField label="Images" reference="image" filter={{ targetType: props.targetType }} target="targetId">
                    <EditableDatagrid editForm={<ImageForm />}>
                        <WithRecord
                            label={t("resources.image.fields.type")}
                            render={(record) => <span>{t(`resources.image.choices.type.${record.type}`)}</span>}
                        />
                        <ImageField source="url" />
                    </EditableDatagrid>
                </ReferenceManyField>
                <ImageAddButton targetType={props.targetType} />
            </>
        </Labeled>
    );
}
