import { Fetcher } from "openapi-typescript-fetch";
import type { paths } from "./openapi-types";

// Declare fetcher for paths
export const fetcher = Fetcher.for<paths>();

export const baseUrl = process.env["PRIVATE_API_BASE_URL"] ?? "";

export const globalConfig = {
    baseUrl,
    init: {
        credentials: "include" as "include" | "omit" | "same-origin"
    }
};

// global configuration
fetcher.configure(globalConfig);

// See bug https://github.com/ajaishankar/openapi-typescript-fetch/issues/13 :
export const dummyArgForOpsWoArg: Record<string, never> = {};
