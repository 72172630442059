/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect } from "react";

import { tokenLogin } from "../../../auth";
import { useRedirect } from "react-admin";
import { isDefined } from "../../typedLoDashUtils";

export function TokenAuth(): JSX.Element {
    const redirect = useRedirect();

    useEffect(() => {
        /*
         * The url looks like :
         * https://domain/#/connexion-par-token?_target_path=domain/#/user-transactions?args&token=theToken
         * Because the _target_path contains /, we can't use URLSearchParams so we have to cook something else
         */
        let splitUrl = window.location.toString().split("?_target_path=");
        splitUrl = splitUrl[1]?.split("&token=") ?? [];

        if (isDefined(splitUrl) && splitUrl.length === 2) {
            const targetPath = splitUrl[0];
            const token = splitUrl[1];

            if (isDefined(token) && isDefined(targetPath)) {
                tokenLogin({ token }).then(
                    () => {
                        redirect(targetPath);
                    },
                    () => {
                        redirect(targetPath);
                    }
                );
            }
        }
    }, [redirect]);

    return <ProgressSpinner />;
}
