export function assert(isFulfilled: boolean, message?: string): asserts isFulfilled {
    if (!isFulfilled) {
        // eslint-disable-next-line no-console
        console.trace();
        throw new Error(message);
    }
}

export function assertIsDefined<T>(val: T, message?: string): asserts val is NonNullable<T> {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-undefined
    assert(val !== undefined && val !== null, message ?? `Expected 'val' to be defined, but received ${val}`);
}
