import { useResourceContext, useTranslate } from "react-admin";
import Typography from "@mui/material/Typography";

interface TitleTextFieldProps {
    type: string;
}

const classes = {
    root: { fontSize: "0.75em", color: "rgba(0, 0, 0, 0.6)", marginBottom: "10px" }
};

export function TitleTextField({ type }: TitleTextFieldProps): JSX.Element {
    const t = useTranslate();
    const resourceCtx = useResourceContext();

    return <Typography sx={classes.root}>{t(`resources.${resourceCtx}.${type}`)}</Typography>;
}
