import { Edit } from "react-admin";

import { StaticPageSimpleFormContent } from "./components/StaticPageSimpleFormContent";
import { EditHeader } from "../../utils/header";

export function StaticPageEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic" title={<EditHeader field="title" />}>
            <StaticPageSimpleFormContent />
        </Edit>
    );
}
