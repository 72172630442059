import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { isDefined } from "../../utils/typedLoDashUtils";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import type { SeasonDTO } from "./SeasonDTO";
import type { SeasonRecord } from "./SeasonRecord";
import { convertIdFilter } from "../../utils/filterUtils";
import { SerieRecord } from "../serie";
import { SerieDTO } from "../serie/SerieDTO";

const findAllTranslated = fetcher.path("/api/content/season/findAll").method("post").create();
const getTranslated = fetcher.path("/api/content/season/{uuid}").method("get").create();
const updateOne = fetcher.path("/api/content/season/{uuid}").method("put").create();
const createOne = fetcher.path("/api/content/season/").method("post").create();
const deleteOne = fetcher.path("/api/content/season/{uuid}").method("delete").create();
const getAllByUuids = fetcher.path("/api/content/season/allByUuids").method("get").create();

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns SeasonRecord
 */

function mapDTOToRecord(dto: SeasonDTO): SeasonRecord {
    // DTO from backend
    const {
        id = 0,
        uuid = "",
        name = "",
        description = "",
        position,
        published = false,
        publicationEndedAt = null,
        publicationStartedAt = null,
        serie
    } = dto;

    // Record for front-end
    return {
        shortId: id,
        id: uuid,
        name,
        description,
        published,
        publicationEndedAt: publicationEndedAt as string,
        publicationStartedAt: publicationStartedAt as string,
        position,
        serie: {
            shortId: serie?.id,
            id: serie?.uuid,
            name: serie?.name
        } as SerieRecord
    };
}

/**
 * Parses the record from frontend to DTO for backend
 * @param record
 * @returns FormatDTO
 */

function mapRecordToDTO(record: SeasonRecord): SeasonDTO {
    // Record from front-end
    const {
        shortId = 0,
        id = "",
        name = "",
        description = "",
        position = 0,
        published = false,
        publicationEndedAt = null,
        publicationStartedAt = null,
        serie
    } = record;

    // DTO for back-end
    return {
        id: shortId,
        name,
        uuid: id as string,
        position,
        description,
        published,
        publicationEndedAt: publicationEndedAt as string,
        publicationStartedAt: publicationStartedAt as string,
        serie: {
            id: serie.shortId,
            name: serie.name,
            uuid: serie.id as string
        } as SerieDTO
    };
}

interface SeasonFilters extends BaseFilters {
    q?: string;
    id: string;
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<SeasonRecord>> {
    const { q: searchTerms, ...filters } = params.filter as SeasonFilters;

    let parameters: FetchArgType<typeof findAllTranslated> = {
        filter: convertIdFilter(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    if (isDefined(searchTerms)) {
        parameters = {
            ...parameters,
            filter: { ...parameters.filter, searchTerms }
        };
    }

    const { data } = await findAllTranslated(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    const result = {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };

    return result;
}

async function getOne(params: LangParams | (number | string)): Promise<SeasonRecord> {
    const {
        data: { content: seasonDTO }
    } = await getTranslated({
        uuid: params as string
    });
    assertIsDefined(seasonDTO);

    return mapDTOToRecord({ id: seasonDTO.id ?? 0, ...seasonDTO });
}

async function getMany(uuids: readonly (number | string)[]): Promise<GetManyResult<SeasonRecord>> {
    const {
        data: { content: seasonDTOs }
    } = await getAllByUuids({
        uuids: uuids as string[]
    });
    assertIsDefined(seasonDTOs);

    return { data: await Promise.all(seasonDTOs.map((dto) => mapDTOToRecord({ id: dto.id ?? 0, ...dto }))) };
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<SeasonRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(params: DeepReadonly<UpdateParams<SeasonRecord>>): Promise<UpdateResult<SeasonRecord>> {
    const payload = mapRecordToDTO(params.data as SeasonRecord);

    const parameters = { uuid: payload.uuid ?? "", ...payload };

    const { data } = await updateOne(parameters);
    const seasonDTO = data.content;
    assertIsDefined(seasonDTO);

    return { data: mapDTOToRecord({ id: parameters.id, ...seasonDTO }) };
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(params: DeepReadonly<CreateParams>): Promise<CreateResult<SeasonRecord>> {
    const payload = mapRecordToDTO(params.data as any);

    assertIsDefined(payload.name);
    const parameters = { ...payload };
    delete (parameters as any)["id"];
    delete (parameters as any)["uuid"];
    const { data } = await createOne(parameters);
    const seasonDTO = data.content;

    assertIsDefined(seasonDTO);

    return { data: mapDTOToRecord({ id: seasonDTO.id as number, ...seasonDTO }) };
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(uuid: number | string): Promise<SeasonRecord> {
    const {
        data: { content: isDeleted }
    } = await deleteOne({
        uuid: uuid as string
    });
    assertIsDefined(isDeleted);

    return {} as SeasonRecord;
}

async function deleteMany(params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    await Promise.all(params.ids.map((id: string) => _delete(id)));
    return {
        data: params.ids.map((id: string) => {
            id;
        })
    };
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<SeasonRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const SeasonDataProvider: MpmDataProvider<SeasonRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne,
    update,
    updateMany
};
