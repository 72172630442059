/* eslint-disable max-lines */

/* eslint-disable consistent-return */
import type {
    GetListResult,
    CreateParams,
    DataProvider,
    GetListParams,
    GetManyParams,
    GetManyReferenceParams,
    GetOneParams,
    UpdateManyParams,
    UpdateParams,
    DeleteParams,
    DeleteManyParams,
    GetOneResult,
    GetManyResult,
    GetManyReferenceResult,
    UpdateResult,
    UpdateManyResult,
    CreateResult,
    DeleteResult,
    DeleteManyResult
} from "react-admin";
import { HttpError } from "react-admin";
import type { DeepReadonly } from "ts-essentials";
import { assert, assertIsDefined } from "../utils/assertions";
import { isClientError, isServerError } from "../utils/HttpStatusCode";
import { isString } from "../utils/typedLoDashUtils";
import { MpmResource } from "../resources";
import type { MpmRecord } from "./MedNumRecord";
import type { LangParams, MpmDataProviderOperationName } from "./MedNumDataProvider";
import { NotImplementedError } from "../utils/NotImplementedError";
import { isArray } from "lodash";

import type {
    GetTreeResult,
    GetRootNodesResult,
    GetParentNodeResult,
    GetChildNodesResult,
    MoveAsNthChildOfResult,
    MoveAsNthSiblingOfOfResult,
    AddRootNodeResult,
    AddChildNodeResult,
    DeleteBranchResult
} from "@react-admin/ra-tree";

function assertIsResource<TRecord extends MpmRecord>(
    maybeResource: MpmResource<TRecord> | undefined,
    resourceName: string
): asserts maybeResource is NonNullable<MpmResource<TRecord>> {
    assertIsDefined(maybeResource, `Unknown MedNum resource ${resourceName}`);
}

function throwOperationError(
    // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
    mpmResource: MpmResource<MpmRecord>,
    error: unknown,
    operation: MpmDataProviderOperationName<MpmRecord>
): never {
    const errorDetails = mpmResource.dataProvider.getErrorDetails(error, operation);
    if (errorDetails) {
        const { status, data } = errorDetails;
        if (isClientError(status)) {
            throw new HttpError("Client side error", status, data);
        } else if (isServerError(status)) {
            throw new HttpError("Server side error", status, data);
        } else {
            throw new Error("Some error");
        }
    } else {
        throw error;
    }
}

async function getList(resource: string, params: DeepReadonly<GetListParams> & LangParams): Promise<GetListResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getList);

    try {
        return await mpmResource.dataProvider.getList(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getList");
    }
}

async function getOne(resource: string, { id: uuid }: DeepReadonly<GetOneParams> & LangParams): Promise<GetOneResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assert(isString(uuid));

    try {
        return {
            data: await mpmResource.dataProvider.getOne(uuid)
        };
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getOne");
    }
}

async function getMany(resource: string, params: DeepReadonly<GetManyParams> & LangParams): Promise<GetManyResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    isArray(params.ids);

    try {
        return await mpmResource.dataProvider.getMany(params.ids);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getMany");
    }
}

async function getManyReference(
    resource: string,
    params: DeepReadonly<GetManyReferenceParams> & LangParams
): Promise<GetManyReferenceResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getList);

    try {
        return await mpmResource.dataProvider.getList(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getList");
    }
}

async function update(resource: string, params: DeepReadonly<UpdateParams> & LangParams): Promise<UpdateResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);

    try {
        return await mpmResource.dataProvider.update(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "update");
    }
}

async function updateMany(_resource: string, _params: DeepReadonly<UpdateManyParams> & LangParams): Promise<UpdateManyResult> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(resource: string, params: DeepReadonly<CreateParams> & LangParams): Promise<CreateResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);

    try {
        return await mpmResource.dataProvider.create(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "create");
    }
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(resource: string, params: DeepReadonly<DeleteParams>): Promise<DeleteResult<MpmRecord>> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);

    try {
        return { data: await mpmResource.dataProvider.delete(params.id as string) };
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "delete");
    }
}

async function deleteMany(resource: string, params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);

    try {
        const result = await mpmResource.dataProvider.deleteMany({ ids: params.ids });
        return result;
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "deleteMany");
    }
}

async function getTree(resource: string, params: DeepReadonly<GetTreeResult> & LangParams): Promise<GetTreeResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getTree);

    try {
        return await mpmResource.dataProvider.getTree(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getTree");
    }
}

async function getRootNodes(resource: string, params: DeepReadonly<GetRootNodesResult> & LangParams): Promise<GetRootNodesResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getRootNodes);

    try {
        return await mpmResource.dataProvider.getRootNodes(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getRootNodes");
    }
}

async function getParentNode(resource: string, params: DeepReadonly<GetParentNodeResult> & LangParams): Promise<GetParentNodeResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getParentNode);

    try {
        return await mpmResource.dataProvider.getParentNode(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getParentNode");
    }
}

async function getChildNodes(resource: string, params: DeepReadonly<GetChildNodesResult> & LangParams): Promise<GetChildNodesResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.getChildNodes);

    try {
        return await mpmResource.dataProvider.getChildNodes(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "getChildNodes");
    }
}

async function moveAsNthChildOf(
    resource: string,
    params: DeepReadonly<MoveAsNthChildOfResult> & LangParams
): Promise<MoveAsNthChildOfResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.moveAsNthChildOf);

    try {
        return await mpmResource.dataProvider.moveAsNthChildOf(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "moveAsNthChildOf");
    }
}

async function moveAsNthSiblingOf(
    resource: string,
    params: DeepReadonly<MoveAsNthSiblingOfOfResult> & LangParams
): Promise<MoveAsNthSiblingOfOfResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.moveAsNthSiblingOf);

    try {
        return await mpmResource.dataProvider.moveAsNthSiblingOf(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "moveAsNthSiblingOf");
    }
}

async function addRootNode(resource: string, params: DeepReadonly<AddRootNodeResult> & LangParams): Promise<AddRootNodeResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.addRootNode);

    try {
        return await mpmResource.dataProvider.addRootNode(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "addRootNode");
    }
}

async function addChildNode(resource: string, params: DeepReadonly<AddChildNodeResult> & LangParams): Promise<AddChildNodeResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.addChildNode);

    try {
        return await mpmResource.dataProvider.addChildNode(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "addChildNode");
    }
}

async function deleteBranch(resource: string, params: DeepReadonly<DeleteBranchResult> & LangParams): Promise<DeleteBranchResult> {
    const mpmResource = MpmResource.fromName(resource);
    assertIsResource(mpmResource, resource);
    assertIsDefined(mpmResource.dataProvider.deleteBranch);

    try {
        return await mpmResource.dataProvider.deleteBranch(params);
    } catch (error: unknown) {
        throwOperationError(mpmResource, error, "deleteBranch");
    }
}

export const dataProvider: DataProvider = {
    addChildNode,
    addRootNode,
    create,
    delete: _delete,
    deleteBranch,
    deleteMany,
    getChildNodes,
    getList,
    getMany,
    getManyReference,
    getOne,
    getParentNode,
    getRootNodes,
    getTree,
    moveAsNthChildOf,
    moveAsNthSiblingOf,
    update,
    updateMany
} as DataProvider;
