import { Show, SimpleShowLayout, TextField } from "react-admin";
import { MarkdownField } from "@react-admin/ra-markdown";
import { ShowHeader } from "../../utils/header";

export function StaticPageShow(): JSX.Element {
    return (
        <Show title={<ShowHeader field="name" />}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="title" />
                <TextField source="description" />
                <MarkdownField source="content" />
            </SimpleShowLayout>
        </Show>
    );
}
