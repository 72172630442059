import { Pagination } from "react-admin";
import type { PaginationProps } from "react-admin";
import { times } from "lodash";

const pageSize = 20;

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export function ListPagination(props: PaginationProps): JSX.Element {
    // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/no-magic-numbers
    return <Pagination rowsPerPageOptions={times(4, (exponent) => pageSize * 2 ** exponent)} {...props} />;
}
