import loDashIsFunction from "lodash/isFunction";
import loDashIsString from "lodash/isString";
import loDashIsBoolean from "lodash/isBoolean";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";

/**
 * Checks whether or not a value is a function.
 * See https://lodash.com/docs/4.17.15#isFunction
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: unknown | null | undefined): value is Function {
    return loDashIsFunction(value);
}

/**
 * Checks whether or not a value is a boolean.
 * See https://lodash.com/docs/4.17.15#isBoolean
 */
export function isBoolean(value: unknown | null | undefined): value is boolean {
    return loDashIsBoolean(value);
}

/**
 * Checks whether or not a value is a string.
 * See https://lodash.com/docs/4.17.15#isString
 */
export function isString(value: unknown | null | undefined): value is string {
    return loDashIsString(value);
}

/**
 * Checks if value is defined, meaning it is not null nor undefined.
 */
export function isDefined<T>(val?: T): val is NonNullable<T> {
    return !isNull(val) && !isUndefined(val);
}
