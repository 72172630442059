import { Datagrid, EditButton, List, ShowButton, TextField, TextInput, useTranslate } from "react-admin";

import { rowStyles } from "../../utils/rowStyles";
import { ListPagination } from "../../utils/components/ListPagination";

import { ListActions } from "../../common/ListActions";

export function ThemeList(): JSX.Element {
    const t = useTranslate();

    const filters = [
        <TextInput key="searchTerms-filter" label={t("resources.theme.fields.name")} source="searchTerms" alwaysOn />,
        <TextInput key="searchTerms-id" label={t("resources.common.fields.id")} source="id" />
    ];

    return (
        <>
            <List hasCreate pagination={<ListPagination />} perPage={20} filters={filters} actions={<ListActions />}>
                <Datagrid rowStyle={rowStyles}>
                    <TextField source="name" />
                    <TextField source="description" />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            </List>
        </>
    );
}
