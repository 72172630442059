import { Create } from "react-admin";

import { StaticPageSimpleFormContent } from "./components/StaticPageSimpleFormContent";

export function StaticPageCreate(): JSX.Element {
    return (
        <Create>
            <StaticPageSimpleFormContent />
        </Create>
    );
}
