import { NumberField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext, useTranslate } from "react-admin";
import { SeasonRecord } from "./SeasonRecord";

export function SeasonShow(): JSX.Element {
    const t = useTranslate();
    const recordContext = useRecordContext<SeasonRecord>();

    return (
        <Show>
            <SimpleShowLayout>
                <ReferenceField source="serie.id" reference="serie" label={t("resources.season.fields.serie")}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="description" />
                {recordContext?.position && <NumberField source="position" />}
            </SimpleShowLayout>
        </Show>
    );
}
