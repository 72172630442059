import get from "lodash/get";
import type { PublicFieldProps } from "react-admin";
import { useTranslate, useRecordContext } from "react-admin";
import type { TypographyProps } from "@mui/material/Typography";
import Typography from "@mui/material/Typography";

import { sanitizeFieldRestProps } from "./sanitizeFieldRestProps";
import { isBoolean, isString } from "../utils/typedLoDashUtils";

const classes = {
    root: {
        textTransform: "uppercase",
        color: "white",
        backgroundColor: "red",
        fontSize: "smaller",
        padding: "5px",
        fontWeight: "bold",
        borderRadius: "5px"
    }
};

export function YesNoBooleanField(props: Omit<PublicFieldProps, "textAlign"> & TypographyProps): JSX.Element {
    const { className, source, ...rest } = props;
    const record = useRecordContext(props);
    const translate = useTranslate();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let value = false;
    if (isString(source)) {
        const anyValue: unknown = get(record, source);
        if (isBoolean(anyValue)) {
            value = anyValue;
        }
    }

    const textI18nKey = value ? "ra.boolean.true" : "ra.boolean.false";

    return (
        <Typography
            component="span"
            variant="body2"
            sx={{
                ...classes.root,
                backgroundColor: value ? "green" : "red"
            }}
            {...sanitizeFieldRestProps(rest)}
        >
            {translate(textI18nKey)}
        </Typography>
    );
}
