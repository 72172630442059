import { assertIsDefined } from "./assertions";
import { isFunction } from "./typedLoDashUtils";

export class NotImplementedError extends Error {
    public constructor(message: string) {
        super(message);
        this.name = "NotImplementedError";
        Object.setPrototypeOf(this, NotImplementedError.prototype);
        this.name = this.constructor.name;
        // eslint-disable-next-line @typescript-eslint/unbound-method
        if (isFunction(Error.captureStackTrace)) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            const { stack } = new Error(message);
            assertIsDefined(stack);
            this.stack = stack;
        }
    }
}
