import type { FetchArgType } from "openapi-typescript-fetch";
import type {
    GetListParams,
    GetListResult,
    GetManyReferenceParams,
    GetManyReferenceResult,
    UpdateParams,
    UpdateManyParams,
    CreateParams,
    DeleteManyParams,
    DeleteManyResult,
    UpdateResult,
    GetManyResult,
    CreateResult
} from "react-admin";
import { isEmpty } from "lodash";
import type { DeepReadonly } from "ts-essentials";

import { fetcher } from "../../data";
import { isDefined } from "../../utils/typedLoDashUtils";
import { assertIsDefined } from "../../utils/assertions";
import type { BaseFilters } from "../../data/BaseFilters";
import { NotImplementedError } from "../../utils/NotImplementedError";
import type { ErrorDetails, LangParams, MpmDataProvider, MpmDataProviderOperationName } from "../../data";

import type { HomeDTO } from "./HomeDTO";
import type { HomeRecord, HomeLayerType, HomeLayerTargetType, HomeLayerItemDisplayType } from "./HomeRecord";
import { convertIdFilter } from "../../utils/filterUtils";

const findAll = fetcher.path("/api/edito/home/findAll").method("post").create();
const getOne = fetcher.path("/api/edito/home/{uuid}").method("get").create();
const updateOne = fetcher.path("/api/edito/home/{uuid}").method("put").create();
const createOne = fetcher.path("/api/edito/home/").method("post").create();
const deleteOne = fetcher.path("/api/edito/home/{uuid}").method("delete").create();
const getAllByUuids = fetcher.path("/api/edito/home/allByUuids").method("get").create();

/**
 * Parses the DTO from backend to record for frontend
 * @param dto
 * @returns HomeRecord
 */

function mapDTOToRecord(dto: HomeDTO): HomeRecord {
    // DTO from backend
    const { id = 0, uuid = "", title = "", description = "", layers: dtoLayers = [] } = dto;

    // Record for front-end
    return {
        shortId: id,
        id: uuid,
        title,
        description,
        layers: dtoLayers?.map((dtoLayer) => {
            return {
                type: dtoLayer.type as HomeLayerType,
                title: dtoLayer.title as string,
                description: dtoLayer.description as string,
                content: dtoLayer.content as string,
                targetType: dtoLayer.targetType as HomeLayerTargetType,
                targetId: dtoLayer.targetUuid as string,
                itemDisplayType: dtoLayer.itemDisplayType as HomeLayerItemDisplayType,
                items:
                    dtoLayer.items?.map((dtoLayerItem) => {
                        return {
                            targetType: dtoLayerItem.targetType,
                            targetId: dtoLayerItem.targetUuid as string
                        };
                    }) ?? ([] as any[])
            };
        })
    };
}

/**
 * Parses the record from frontend to DTO for backend
 * @param record
 * @returns HomeDTO
 */

function mapRecordToDTO(record: HomeRecord): HomeDTO {
    // Record from front-end
    const { shortId = 0, id = "", title = "", description = "", layers: recordLayers = [] } = record;

    // DTO for back-end
    return {
        id: shortId,
        title,
        uuid: id as string,
        description,
        layers: recordLayers?.map((recordLayer) => {
            return {
                type: recordLayer.type,
                title: recordLayer.title,
                description: recordLayer.description,
                content: recordLayer.content,
                targetType: recordLayer.targetType as string,
                targetUuid: recordLayer.targetId as string,
                itemDisplayType: recordLayer.itemDisplayType,
                items:
                    recordLayer.items?.map((recordLayerItem) => {
                        return {
                            targetType: recordLayerItem.targetType,
                            targetUuid: recordLayerItem.targetId as string
                        };
                    }) ?? ([] as any[])
            };
        })
    };
}

interface HomeFilters extends BaseFilters {
    q?: string;
    id: string;
}

async function getList(params: DeepReadonly<GetListParams>): Promise<GetListResult<HomeRecord>> {
    const { q: searchTerms, ...filters } = params.filter as HomeFilters;

    let parameters: FetchArgType<typeof findAll> = {
        filter: convertIdFilter(filters),
        pageable: {
            page: params.pagination.page - 1,
            size: params.pagination.perPage,
            sort: isEmpty(params.sort.field) ? [] : [`${params.sort.field},${params.sort.order}`]
        }
    } as const;

    if (isDefined(searchTerms)) {
        parameters = {
            ...parameters,
            filter: { ...parameters.filter, searchTerms }
        };
    }

    const { data } = await findAll(parameters);
    const dataPage = data.content;
    assertIsDefined(dataPage);
    const total = dataPage.totalElements;
    assertIsDefined(total);
    assertIsDefined(dataPage.content);

    return {
        data: dataPage.content.map((content) => mapDTOToRecord({ id: content.id ?? 0, ...content })),
        total
    };
}

async function _getOne(params: LangParams | (number | string)): Promise<HomeRecord> {
    const {
        data: { content: selectionDTO }
    } = await getOne({
        uuid: params as string
    });
    assertIsDefined(selectionDTO);

    return mapDTOToRecord({ id: selectionDTO.id ?? 0, ...selectionDTO });
}

async function getMany(uuids: readonly (number | string)[]): Promise<GetManyResult<HomeRecord>> {
    const {
        data: { content: selectionDTO }
    } = await getAllByUuids({
        uuids: uuids as string[]
    });
    assertIsDefined(selectionDTO);

    return { data: await Promise.all(selectionDTO.map((dto) => mapDTOToRecord({ id: dto.id ?? 0, ...dto }))) };
}

async function getManyReference(_params: DeepReadonly<GetManyReferenceParams>): Promise<GetManyReferenceResult<HomeRecord>> {
    return Promise.reject(new NotImplementedError("getManyReference"));
}

async function update(params: DeepReadonly<UpdateParams<HomeRecord>>): Promise<UpdateResult<HomeRecord>> {
    const payload = mapRecordToDTO(params.data as HomeRecord);
    const parameters = { uuid: payload.uuid ?? "", ...payload };

    const { data } = await updateOne(parameters);
    const homeDTO = data.content;
    assertIsDefined(homeDTO);

    return { data: mapDTOToRecord({ id: parameters.id, ...homeDTO }) };
}

async function updateMany(_params: DeepReadonly<UpdateManyParams>): Promise<string[]> {
    return Promise.reject(new NotImplementedError("updateMany"));
}

async function create(params: DeepReadonly<CreateParams>): Promise<CreateResult<HomeRecord>> {
    const payload = mapRecordToDTO(params.data as any);

    assertIsDefined(payload.title);
    const parameters = { ...payload };
    delete (parameters as any)["id"];
    delete (parameters as any)["uuid"];
    const { data } = await createOne(parameters);
    const homeDTO = data.content;

    assertIsDefined(homeDTO);

    return { data: mapDTOToRecord({ id: homeDTO.id as number, ...homeDTO }) };
}

// eslint-disable-next-line no-underscore-dangle
async function _delete(uuid: number | string): Promise<HomeRecord> {
    const {
        data: { content: isDeleted }
    } = await deleteOne({
        uuid: uuid as string
    });
    assertIsDefined(isDeleted);

    return {} as HomeRecord;
}

async function deleteMany(_params: DeepReadonly<DeleteManyParams>): Promise<DeleteManyResult> {
    return Promise.reject(new NotImplementedError("deleteMany"));
}

function getErrorDetails(_error: unknown, _operation: MpmDataProviderOperationName<HomeRecord>): ErrorDetails | undefined {
    // eslint-disable-next-line no-undefined
    return undefined;
}

export const HomeDataProvider: MpmDataProvider<HomeRecord> = {
    create,
    delete: _delete,
    deleteMany,
    getErrorDetails,
    getList,
    getMany,
    getManyReference,
    getOne: _getOne,
    update,
    updateMany
};
