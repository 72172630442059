/* eslint-disable-next-line react/jsx-no-constructed-context-values,import/no-unresolved */
// cSpell: ignore treeselect, primereact, treenode

// eslint-disable-next-line import/no-unresolved
import type TreeNode from "primereact/treenode";
import type { PropsWithChildren } from "react";
import { useCallback, createContext, useMemo, useState } from "react";
import type { TreeSelectSelectionKeys } from "primereact/treeselect";
import type { ApiResponse } from "openapi-typescript-fetch";

import { fetcher } from "../../data";

interface AdministrativeEntityContextProps {
    administrativeEntityTree: TreeNode[];
    selectedAdministrativeEntity: TreeSelectSelectionKeys;
    onSelect: (selected: TreeSelectSelectionKeys) => Promise<ApiResponse | null>;
    fetchAdministrativeEntityTree: () => Promise<void>;
}

export const AdministrativeEntityContext = createContext<AdministrativeEntityContextProps>({} as AdministrativeEntityContextProps);

const fetchTree = fetcher.path("/api/entity/administrativeEntity/tree").method("get").create();
const updateAdministrativeEntity = fetcher.path("/api/user/user/administrativeEntity/{administrativeEntityUuid}").method("put").create();

// eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
export function AdministrativeEntityContextProvider({ children }: PropsWithChildren): JSX.Element {
    const [administrativeEntityTree, setAdministrativeEntityTree] = useState<TreeNode[] | null>(null);
    const [selectedAdministrativeEntity, setSelectedAdministrativeEntity] = useState<TreeSelectSelectionKeys>("");

    const fetchAdministrativeEntityTree = useCallback(async () => {
        if (administrativeEntityTree === null) {
            const { data } = await fetchTree({});
            if (data.content) {
                setAdministrativeEntityTree([data.content as TreeNode]);
            }
        }
    }, [administrativeEntityTree]);

    const onSelect = useCallback(
        async (selected: TreeSelectSelectionKeys): Promise<ApiResponse | null> => {
            if (selected !== selectedAdministrativeEntity) {
                setSelectedAdministrativeEntity(selected);
                const updateResult = await updateAdministrativeEntity({ administrativeEntityUuid: selected as string });
                return updateResult;
            }
            return null;
        },
        [selectedAdministrativeEntity]
    );

    const props = useMemo(() => {
        return {
            administrativeEntityTree: administrativeEntityTree ?? [],
            selectedAdministrativeEntity,
            fetchAdministrativeEntityTree,
            onSelect
        };
    }, [administrativeEntityTree, selectedAdministrativeEntity, fetchAdministrativeEntityTree, onSelect]);

    return <AdministrativeEntityContext.Provider value={props}>{children}</AdministrativeEntityContext.Provider>;
}
