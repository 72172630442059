import { Edit } from "react-admin";

import { ProductSimpleFormContent } from "./components/ProductSimpleFormContent";

export function ProductEdit(): JSX.Element {
    return (
        <Edit mutationMode="pessimistic">
            <ProductSimpleFormContent />
        </Edit>
    );
}
