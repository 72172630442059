import { useEditContext, useShowContext, useTranslate } from "react-admin";

export const EditHeader = (props: { field: string }) => {
    const context = useEditContext();
    const t = useTranslate();

    // the record can be empty while loading
    if (!context?.record) return null;

    return (
        <span>
            {t(`resources.${context?.resource}.name`, { smart_count: 1 })} {context?.record?.[props.field]}
        </span>
    );
};

export const ShowHeader = (props: { field: string }) => {
    const context = useShowContext();
    const t = useTranslate();

    // the record can be empty while loading
    if (!context?.record) return null;

    return (
        <span>
            {t(`resources.${context?.resource}.name`, { smart_count: 1 })} {context?.record?.[props.field]}
        </span>
    );
};
