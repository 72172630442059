import { Create } from "react-admin";

import { ProductSimpleFormContent } from "./components/ProductSimpleFormContent";

export function ProductCreate(): JSX.Element {
    return (
        <Create>
            <ProductSimpleFormContent />
        </Create>
    );
}
